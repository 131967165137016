export default [
  {
    title: 'Avance de Aprendizajes',
    icon: 'ClipboardIcon',
    route: 'avances',
    resource: 'avances',
    action: 'read',
    // children: [
    //   {
    //     title: 'Avances de Aprendizajes',
    //   },
    // ],
  },
]
