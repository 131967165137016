export default [
  {
    title: 'Resumen Anual',
    // icon: 'GridIcon',
    // icon: 'PenToolIcon',
    icon: 'EyeIcon',
    route: 'resumenanual',
    resource: 'resumenanual',
    action: 'read',
  },
]
