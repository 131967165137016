<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ user.nombres }} {{ user.primerApellido }} {{ user.segundoApellido }}
        </p>
        <span
          class="user-status"
        >
          {{ nombreEstablecimiento }}
          <span
            v-if="user.rolActivo !== 'Super Administrador'
              && user.rolActivo !== 'Administrador Daem'"
          >
          |
          </span>
          {{ user.rolActivo }}
        </span>
      </div>
      <b-avatar
        size="40"
        variant="light-primary"
        badge
        :src="user.avatar"
        :text="avatarText(`${user.nombres}
          ${user.primerApellido} ${user.segundoApellido}`)"
        :title="avatarText(`${user.nombres}
          ${user.primerApellido} ${user.segundoApellido}`)"
        class="badge-minimal"
        badge-variant="success"
      />
    </template>

    <b-dropdown-item
      :to="{ name: 'ajustes' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Ajustes</span>
    </b-dropdown-item>
    <!-- <b-dropdown-item
      :to="{ name: 'apps-email' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MailIcon"
        class="mr-50"
      />
      <span>Inbox</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-todo' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CheckSquareIcon"
        class="mr-50"
      />
      <span>Task</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item> -->

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click.prevent="signOut"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Cerrar Sesiónsss</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
// import {
//   BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
// } from 'bootstrap-vue'
// import { initialAbility } from '@/libs/acl/config'
// import useJwt from '@/auth/jwt/useJwt'

import {
  // BLink, BNavbarNav, BNavItemDropdown,
  BNavItemDropdown, BDropdownItem, BAvatar, BDropdownDivider,
} from 'bootstrap-vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { mapGetters, mapActions } from 'vuex'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BLink,
    // BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    // DarkToggler,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  watch: {
    user() {
      this.getEstablecimientoActivo(this.user)
    },
  },
  data() {
    return {
      nombreEstablecimiento: null,
    }
  },
  mounted() {
    this.getEstablecimientoActivo(this.user)
  },
  methods: {
    ...mapActions({
      signOutAction: 'auth/signOut',
    }),
    getEstablecimientoActivo(usuario) {
      const {
        establecimientos,
        idEstablecimientoActivo,
      } = usuario
      const establecimientoActivo = establecimientos.find(
        es => es.idEstablecimiento === idEstablecimientoActivo,
      )
      if (typeof establecimientoActivo !== 'undefined') {
        this.nombreEstablecimiento = establecimientoActivo.nombreEstablecimiento
      }
    },
    signOut() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Cerrando Sesión...',
          icon: 'RefreshCcwIcon',
          variant: 'primary',
          text: `Hasta pronto ${this.user.nombres}
                ${this.user.primerApellido} ${this.user.segundoApellido}! 👋 `,
        },
      },
      {
        timeout: 3000,
      })
      this.signOutAction().then(() => {
        this.$router.replace({
          name: 'login',
        })
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>
