<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-1"
      style="margin-top: -10px !important;"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <!-- <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2> -->
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-row>
        <b-col cols="6">
        </b-col>
        <b-col cols="6"
          v-for="item in $route.meta.breadcrumb"
          :key="item.text"
          :to="item.to"
        >
          <b-overlay
            :show="configs.cargandoCursos"
            spinner-variant="primary"
            variant="semi-dark"
          >
            <b-form-select
              v-if="typeof item.historico === 'undefined'
                ? false
                : item.historico"
              size="sm"
              v-model="idPeriodoSelected"
              :options="optionsHistorico"
              @input="changePeriodo()"
            />
          </b-overlay>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BFormSelect, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BBreadcrumb, BBreadcrumbItem, BRow, BCol, BFormSelect, BOverlay,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getPeriodos: 'periodos/getPeriodos',
    }),
  },
  data() {
    return {
      configs: {
        cargandoPeriodos: true,
      },
      optionsHistorico: [],
      idPeriodoSelected: null,
    }
  },
  watch: {
    '$route.meta': {
      deep: true,
      handler() {
        this.setPeriodoHistorico(null)
        this.setPeriodos()
      },
    },
  },
  mounted() {
    this.setPeriodos()
  },
  methods: {
    ...mapActions({
      setPeriodoHistorico: 'periodos/setPeriodoHistorico',
      fetchAttempt: 'auth/attempt',
      fetchPeriodos: 'periodos/fetchPeriodos',
    }),
    async setPeriodos() {
      this.configs.cargandoPeriodos = true
      await this.fetchPeriodos()
      this.optionsHistorico = []
      this.getPeriodos.forEach(periodo => {
        this.optionsHistorico.push({
          value: periodo.id,
          text: periodo.nombre,
        })
      })
      this.idPeriodoSelected = this.getUser.idPeriodoActivo
      await this.changePeriodo()
      this.configs.cargandoPeriodos = false
    },
    async changePeriodo() {
      await this.setPeriodoHistorico(this.idPeriodoSelected)
    },
  },
}
</script>
